import React from "react";
import "./App.css";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./logo.png" className="App-logo" alt="logo" />
        <p>Pedidos por Whatsapp al</p>
        <a
          className="App-link"
          href="https://wa.me/5491162279531"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon className="App-icon" /> <span>54 11 6227-9531</span>
        </a>

        <p>Delivery sólo zona caballito</p>
      </header>
    </div>
  );
}

export default App;
